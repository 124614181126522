import { defineComponent, onMounted } from "vue";
import { showLoading, isMobile, setAuthCookie, getCookie, parseToken, clearAuthorization, TokenCorpKey, TokenKey, } from "../../utils";
import { setAidCookie, redirect } from "../../config/auth";
import { ddCorpLogin } from "../../utils/dd-auth";
import { parseUrl } from "../../utils/url-helper";
import { renewalToken } from "../../apis";
export default defineComponent({
    name: "LoginddCrop",
    setup() {
        const isTokenOverTime = async function (params) {
            let isLogin = false;
            const jwt = getCookie(TokenKey);
            const tokenCorp = getCookie(TokenCorpKey);
            const token = parseToken(jwt);
            if (jwt && tokenCorp && params.corpId === tokenCorp && !token.associatedmainenginecode) {
                if ("exp" in token) {
                    const FOUR_HOURS = 4 * 60 * 60;
                    const now = parseInt(Date.now() / 1000 + "");
                    if (token.exp - now < FOUR_HOURS) {
                        const ret = await renewalToken({ token: jwt });
                        if (ret) {
                            if (ret.errcode !== 0) {
                                alert(ret.errmsg);
                            }
                            else {
                                // 续期成功，刷新cookie
                                isLogin = true;
                                setAuthCookie(ret, params.corpId);
                                setAidCookie(params);
                            }
                        }
                    }
                    else {
                        isLogin = true;
                    }
                }
            }
            return isLogin;
        };
        onMounted(async () => {
            // 集成钉钉重定向用
            showLoading(true);
            const params = parseUrl(window.location.href);
            params.isDingtalkCorp = true;
            if (isMobile) {
                window.location.replace(`${window.location.origin}/index.html?${window.location.href.split('?')[1]}&isDingtalkCorp=true&t=${new Date().getTime()}`);
                return;
            }
            const isLogin = await isTokenOverTime(params);
            if (isLogin) {
                redirect(params);
            }
            else {
                const res = await ddCorpLogin(params);
                if (!res.errcode) {
                    clearAuthorization();
                    setAuthCookie(res, params.corpId);
                    setAidCookie(params);
                    redirect(params);
                }
                else {
                    showLoading(false);
                    alert(res.errmsg);
                }
            }
        });
    },
});
